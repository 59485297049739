import { httpClient } from './axiosClients'

// We provide an empty object as default value to payload because we de-structure it later on
export function getOffices (payload = {}) {
  const { url = '/api/office', params } = payload
  return httpClient.get(url, { params })
}

export function getCollaborators () {
  return httpClient.get('/api/collaborator')
}

export function searchCollaborators ({ params }) {
  return httpClient.get('/api/collaborators/search', { params })
}

export function getOfficeCollaborators (officeId) {
  return httpClient.get(`/api/office/${officeId}`)
}

export function getImmoConnectUrlForCollaborator (collaboratorId) {
  return httpClient.get(`/api/collaborators/${collaboratorId}/immoconnect-url`)
}

export function getCollaboratorPreferences (collaboratorId) {
  return httpClient.get(`/api/collaborators/${collaboratorId}/preferences`)
}

export function updateCollaboratorPreferences (collaboratorId, payload) {
  return httpClient.patch(`/api/collaborators/${collaboratorId}/preferences`, payload)
}

export function getImmoConnectStatus () {
  return httpClient.get('/api/immo-connect/status')
}

export function startImmoConnectConnection () {
  return httpClient.post('/api/immo-connect/start')
}

export function finishImmoConnectConnection (payload) {
  return httpClient.post('/api/immo-connect/finish', payload)
}

export function getCollaboratorTarget (userId) {
  return httpClient.get(`/api/organization/collaborators/${userId}/target`)
}

export function getCompanies ({ params }) {
  return httpClient.get('/api/companies', { params })
}

export function collaboratorHasCredential (collaboratorId, typeId) {
  return httpClient.get(`/api/collaborators/${collaboratorId}/has-third-party-credential?type_id=${typeId}`)
}
